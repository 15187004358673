.progressbar {
    counter-reset: step;
    display: inline-block;
    padding-inline-start: 0;
}
.progressbar li {
    list-style-type: none;
    width: 30px;
    float: left;
    font-size: 12px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: #c9cfd3;
}
.progressbar li:after {
    width: 10px;
    height: 10px;
    content: "";
    counter-increment: step;
    line-height: 30px;
    border: 2px solid #c9cfd3;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: #c9cfd3;
}
.progressbar li:before {
    width: 100%;
    height: 2px;
    content: "";
    position: absolute;
    background-color: #c9cfd3;
    top: 6px;
    left: -50%;
    z-index: -1;
}
.progressbar li:first-child:before {
    content: none;
}
.progressbar li.active {
    color: #8d2d46;
}
.progressbar li.active:after {
    border-color: #8d2d46;
    background-color: #8d2d46;
}
.progressbar li.active:before {
    background-color: #8d2d46;
}
